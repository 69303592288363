import type { ElementType } from 'react';

import IconInstagram from '@/components/Icons/IconInstagram';
import IconTelegram from '@/components/Icons/IconTelegram';
import IconTikTok from '@/components/Icons/IconTikTok';
import IconVk from '@/components/Icons/IconVk';
import IconWhatsapp from '@/components/Icons/IconWhatsapp';
import { WarehouseDetail } from '@/features/Warehouse';
import {
  AgentDetail,
  Agents,
  Balance,
  Calendar,
  Calls,
  Correction,
  CreateAgent,
  CreateOrder,
  DealDetail,
  Debt,
  Employee,
  Expenses,
  Finance,
  FinanceCheck,
  FinanceStatement,
  Goods,
  Jobs,
  Order,
  Payments,
  PaymentTerminal,
  Receipts,
  ResetPassword,
  Salary,
  SalaryCalculation,
  SalaryProduction,
  Settings,
  SettingsDirectory,
  SettingsOrders,
  SettingsService,
  ShipmentProfit,
  Stock,
  Telephony,
  Warehouse,
  WarehouseArrival,
  WarehouseArrivalCreate,
  WarehouseArrivalDetail,
  WarehouseTransfer,
  WarehouseTransferCreate,
  WorkSchedule,
} from '@/pages';
import { SocialMediaPlatformEnum } from '@/types';

export const PATHS = {
  AGENTS: '/agents',
  AGENT_DETAIL: '/agents/:agentId/:agentType',
  ARRIVAL: '/arrival',
  ARRIVAL_DETAIL: '/arrival/:id',
  ARRIVAL_CREATE: '/arrival/create',
  AUTH: '/auth',
  BALANCE: '/finance/balance',
  CALLS: '/calls',
  CALENDAR: '/calendar',
  CORRECTION: '/stock/correction',
  CREATE_AGENT: '/agents/create/:agentType',
  CREATE_LEGAL_AGENT: '/agents/create/legal',
  CREATE_INDIVIDUAL_AGENT: '/agents/create/individual',
  DDS: '/dds',
  DEAL: '/deal',
  DEAL_DETAIL: '/deal/:id',
  DEBT: '/stock/debt',
  EMPLOYEE: '/employee',
  EXPENSES: '/finance/expenses',
  FINANCE: '/finance',
  FINANCE_CHECK: '/finance/check',
  FINANCE_STATEMENT: '/finance/statement',
  GOODS: '/stock/goods',
  JOBS: '/jobs',
  // JOBS: "/",
  ORDER: '/',
  ORDER_VIEW: '/orders/:id',
  MOVEMENTS: '/stock/movements',
  NEW_PASSWORD: '/new-password',
  RECEIPTS: '/stock/receipts',
  REMAINS: '/stock/remains',
  SALARY: '/salary',
  SALARY_CALCULATION: '/salary/calculation',
  SALARY_PRODUCTION: '/salary/production',
  SETTINGS: '/settings',
  SETTINGS_DIRECTORY: '/settings/directory',
  SETTINGS_ORDERS: '/settings/orders',
  SETTINGS_SERVICE: '/settings/service',
  BALANCE_AND_CACHE: '/settings/balance',
  SHIPMENT_PROFIT: '/finance/shipment-profit',
  STOCK: '/stock',
  TELEPHONY: '/telephony',
  RESET_PASSWORD: '/reset-password',
  TRANSACTIONS: '/finance/transactions',
  TRANSFER: '/transfer',
  TRANSFER_CREATE: '/transfer/create',
  PAYMENTS: '/finance/payments',
  PAYMENT_TERMINAL: '/finance/payment-terminal',
  WORK_SCHEDULE: '/salary/work-schedule',
  WAREHOUSE: '/warehouse',
  WAREHOUSE_DETAIL: '/warehouse/:id',
};

export const AUTH_TOKEN_COOKIE_NAME = 'authToken';
export const SUBDIVISION = 'subdivision';

export const pages = [
  {
    Component: Agents,
    path: PATHS.AGENTS,
    isPrivate: true,
  },
  {
    Component: AgentDetail,
    path: PATHS.AGENT_DETAIL,
    isPrivate: true,
  },
  // {
  //   Component: Auth,
  //   path: PATHS.AUTH,
  //   isPrivate: false,
  // },
  {
    Component: Balance,
    path: PATHS.BALANCE,
    isPrivate: true,
  },
  {
    Component: PaymentTerminal,
    path: PATHS.PAYMENT_TERMINAL,
    isPrivate: true,
  },
  {
    Component: Calendar,
    path: PATHS.CALENDAR,
    isPrivate: true,
  },
  {
    Component: Calls,
    path: PATHS.CALLS,
    isPrivate: true,
  },
  {
    Component: Correction,
    path: PATHS.CORRECTION,
    isPrivate: true,
  },
  {
    Component: CreateAgent,
    path: PATHS.CREATE_AGENT,
    isPrivate: true,
  },
  {
    Component: Debt,
    path: PATHS.DEBT,
    isPrivate: true,
  },
  {
    Component: Order,
    path: PATHS.ORDER,
    isPrivate: true,
  },
  {
    Component: CreateOrder,
    path: PATHS.ORDER_VIEW,
    isPrivate: true,
  },
  {
    Component: DealDetail,
    path: PATHS.DEAL_DETAIL,
    isPrivate: true,
  },
  {
    Component: Employee,
    path: PATHS.EMPLOYEE,
    isPrivate: true,
  },
  {
    Component: Expenses,
    path: PATHS.EXPENSES,
    isPrivate: true,
  },
  {
    Component: Finance,
    path: PATHS.FINANCE,
    isPrivate: true,
  },
  {
    Component: FinanceCheck,
    path: PATHS.FINANCE_CHECK,
    isPrivate: true,
  },
  {
    Component: FinanceStatement,
    path: PATHS.FINANCE_STATEMENT,
    isPrivate: true,
  },
  {
    Component: Goods,
    path: PATHS.GOODS,
    isPrivate: true,
  },
  {
    Component: Jobs,
    path: PATHS.JOBS,
    isPrivate: true,
  },

  {
    Component: WarehouseTransfer,
    path: PATHS.TRANSFER,
    isPrivate: true,
  },
  // {
  //   Component: NewPassword,
  //   path: PATHS.NEW_PASSWORD,
  //   isPrivate: false,
  // },
  {
    Component: Receipts,
    path: PATHS.RECEIPTS,
    isPrivate: true,
  },
  {
    Component: ResetPassword,
    path: PATHS.RESET_PASSWORD,
    isPrivate: false,
  },
  {
    Component: Salary,
    path: PATHS.SALARY,
    isPrivate: true,
  },
  {
    Component: SalaryCalculation,
    path: PATHS.SALARY_CALCULATION,
    isPrivate: true,
  },
  {
    Component: SalaryProduction,
    path: PATHS.SALARY_PRODUCTION,
    isPrivate: true,
  },
  {
    Component: SettingsService,
    path: PATHS.SETTINGS_SERVICE,
    isPrivate: true,
  },
  {
    Component: Settings,
    path: PATHS.SETTINGS,
    isPrivate: true,
  },
  {
    Component: SettingsDirectory,
    path: PATHS.SETTINGS_DIRECTORY,
    isPrivate: true,
  },
  {
    Component: SettingsOrders,
    path: PATHS.SETTINGS_ORDERS,
    isPrivate: true,
  },
  {
    Component: ShipmentProfit,
    path: PATHS.SHIPMENT_PROFIT,
    isPrivate: true,
  },
  {
    Component: Stock,
    path: PATHS.STOCK,
    isPrivate: true,
  },
  {
    Component: Telephony,
    path: PATHS.TELEPHONY,
    isPrivate: true,
  },
  {
    Component: Payments,
    path: PATHS.PAYMENTS,
    isPrivate: true,
  },
  {
    Component: Warehouse,
    path: PATHS.WAREHOUSE,
    isPrivate: true,
  },
  {
    Component: WarehouseArrival,
    path: PATHS.ARRIVAL,
    isPrivate: true,
  },
  {
    Component: WarehouseArrivalDetail,
    path: PATHS.ARRIVAL_DETAIL,
    isPrivate: true,
  },
  {
    Component: WarehouseArrivalCreate,
    path: PATHS.ARRIVAL_CREATE,
    isPrivate: true,
  },
  {
    Component: WarehouseDetail,
    path: PATHS.WAREHOUSE_DETAIL,
    isPrivate: true,
  },
  {
    Component: WarehouseTransferCreate,
    path: PATHS.TRANSFER_CREATE,
    isPrivate: true,
  },
  {
    Component: WorkSchedule,
    path: PATHS.WORK_SCHEDULE,
    isPrivate: true,
  },
];

export enum EVENTS {
  AUTH_ERROR = 'authError',
  FETCH_JOBS_BY_CATEGORY = 'fetchJobsByCategory',
  REFRESH_DATA = 'refreshData',
  UPDATE_COLUMNS_FROM_STORAGE = 'updateColumnsFromStorage',
  VALIDATE_ADDRESS = 'validateAddress',
}

export const socialMedias = [
  { id: SocialMediaPlatformEnum.Vk, value: 'Вконтакте' },
  { id: SocialMediaPlatformEnum.Instagram, value: 'Instagram' },
  { id: SocialMediaPlatformEnum.Telegram, value: 'Телеграм' },
  { id: SocialMediaPlatformEnum.Whatsapp, value: 'Whatsapp' },
  { id: SocialMediaPlatformEnum.Tiktok, value: 'Tiktok' },
];

export const socialMediaIcons: Record<SocialMediaPlatformEnum, ElementType> = {
  [SocialMediaPlatformEnum.Telegram]: IconTelegram,
  [SocialMediaPlatformEnum.Whatsapp]: IconWhatsapp,
  [SocialMediaPlatformEnum.Vk]: IconVk,
  [SocialMediaPlatformEnum.Instagram]: IconInstagram,
  [SocialMediaPlatformEnum.Tiktok]: IconTikTok,
};

export const EMPTY_STRING = '';
