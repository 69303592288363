import type {
  CreateIndividualAgentService,
  CreateLegalAgentService,
  UpdateIndividualAgentService,
} from './components/AgentForm/types';

import api from '@/api';

export const createIndividualAgent: CreateIndividualAgentService = async (body) => {
  const response = await api.post('/individual-counterpart', body);
  return response.data;
};

export const createLegalAgent: CreateLegalAgentService = async (body) => {
  const response = await api.post('/legal-counterpart', body);
  return response.data;
};

export const updateLegalAgent = async (id: string, body: unknown) => {
  const response = await api.patch(`/legal-counterpart/${id}`, body);
  return response.data;
};

export const updateIndividualAgent: UpdateIndividualAgentService = async (id, body) => {
  const response = await api.patch(`/individual-counterpart/${id}`, body);
  return response.data;
};

export const updateCarServiceOwner = async (id: string, input: any) => {
  const response = await api.patch(`/car-service-owner/${id}`, input);
  return response.data;
};

export const fetchSearchAgents = async (query: string) => {
  const response = await api.get(`/counterpart/search?query=${query}`);
  return response.data;
};
