import { Scrollbar } from 'react-scrollbars-custom';
import Table from '@/components/UI/Table';
import { useMutation } from 'react-query';
import { fetchGetCalls } from '@/features/Telephony/services';
import { useEffect, useState } from 'react';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { getCallDuration } from '@/features/Telephony/utils';
import { getBaseDate } from '@/utils/date';
import { callTypes } from '@/features/Telephony/constants';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import IconPlay from '@/components/Icons/IconPlay';
import { orderStatuses } from '@/features/Order/constants';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import Modal from '@/components/UI/Modal';
import { TelephonyCallModal } from '@/features/Telephony/components/TelephonyCallModal';
import Pagination from '@/components/UI/Pagination';
import { If } from '@/components/ConditionalRendering/If';
import { useTable } from '@/hooks';

export const TelephonyListTable = () => {
  const {
    data,
    mutateAsync: mutateFetchGetCalls,
    isLoading,
  } = useMutation({
    mutationKey: ['call'],
    mutationFn: async () => {
      const data = await fetchGetCalls();
      setTotalPages(data.totalPages || 1);
      setTotalItems(data.totalItems || 0);
      return data;
    },
  });

  const [recordModal, setRecordModal] = useState<{ id: string | null; open: boolean }>({
    id: null,
    open: false,
  });

  const { pageNumber, pageSize, totalPages, totalItems, handlePageChange, setTotalItems, setTotalPages } = useTable();

  const handleCloseModal = () => {
    setRecordModal({
      id: null,
      open: false,
    });
  };

  useEffect(() => {
    mutateFetchGetCalls();
  }, []);

  return (
    <>
      <Scrollbar>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <Table.Cell>Тип звонка</Table.Cell>
              <Table.Cell>Менеджер</Table.Cell>
              <Table.Cell>Подразделение</Table.Cell>
              <Table.Cell>Дата и время</Table.Cell>
              <Table.Cell>Продолжительность</Table.Cell>
              <Table.Cell>Номер сделки</Table.Cell>
              <Table.Cell>Статус сделки</Table.Cell>
            </Table.Row>
          }
          isEmpty={isEmpty(data?.items)}
          isFetching={isLoading}
        >
          {data?.items.map((call) => (
            <Table.Row key={call._id}>
              <Table.Cell>{callTypes[call.type]}</Table.Cell>
              <Table.Cell>{getAgentFullName(call.manager.counterpartProfile)}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>{getBaseDate(call.date)}</Table.Cell>
              <Table.Cell>
                <Flex alignItems="center" columnGap="xs">
                  <IconButton
                    iconSize={10}
                    size="xxs"
                    icon={() => <IconPlay size={10} />}
                    variant="dark"
                    onClick={() =>
                      setRecordModal({
                        id: call.callId,
                        open: true,
                      })
                    }
                  />
                  {getCallDuration(call.duration)}
                </Flex>
              </Table.Cell>
              <Table.Cell>
                <span className="text-main-default">{call.order?.increment}</span>
              </Table.Cell>
              <Table.Cell>{orderStatuses[call.order?.status]}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={!isEmpty(data?.items)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>

      <Modal onClose={handleCloseModal} isOpen={recordModal.open}>
        <TelephonyCallModal callId={recordModal.id as string} />
      </Modal>
    </>
  );
};
