import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Scrollbar } from 'react-scrollbars-custom';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { isArray, isEmpty, isString } from 'lodash';
import { AutoComplete } from 'rsuite';

import { FormFields } from './constants';
import { warehouseArrivalFormSchema } from './schema';
import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Container from '@/components/Container';
import IconAdd from '@/components/Icons/IconAdd';
import IconArrowDown from '@/components/Icons/IconArrowDown';
import IconArrowSubDown from '@/components/Icons/IconArrowSubDown';
import IconArrowUp from '@/components/Icons/IconArrowUp';
import IconCheck from '@/components/Icons/IconCheck';
import IconMinus from '@/components/Icons/IconMinus';
import IconPlus from '@/components/Icons/IconPlus';
import LoadingIndicatorPage from '@/components/LoadingIndicatorPage';
import Button from '@/components/UI/Button';
import DatePicker from '@/components/UI/DatePicker';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import IconButton from '@/components/UI/IconButton';
import Select from '@/components/UI/Select';
import Table from '@/components/UI/Table';
import { EVENTS, PATHS } from '@/constants';
import { getAgentsByRole } from '@/features/Agent/utils';
import { ProductForm } from '@/features/Product';
import { fetchSearchProducts } from '@/features/Product/services';
import type { GroupProductEntity, ProductEntity } from '@/features/Product/types';
import {
  initialValues,
  warehouseArrivalDocuments,
} from '@/features/Warehouse/components/WarehouseArrivalForm/constants';
import WarehouseProductModal from '@/features/Warehouse/components/WarehouseProductModal';
import WarehouseProductStockModal from '@/features/Warehouse/components/WarehouseProductStockModal';
import {
  createWarehouseArrival,
  getWarehouseArrivalById,
  getWarehouses,
  updateWarehouseArrival,
} from '@/features/Warehouse/services';
import type {
  WarehouseArrivalDocumentTypeEnum,
  WarehouseArrivalEntity,
  WarehouseEntity,
} from '@/features/Warehouse/types';
import { fetchSuppliers } from '@/pages/AgentDetail/components/AgentCallsListTable/service';
import { getAgentFullName } from '@/pages/AgentDetail/utils';
import { getIndividualAgents } from '@/pages/Agents/components/AgentsListTable/service';
import type { CounterpartAccount, IndividualAgent } from '@/types';
import { CounterpartRoleEnum, CounterpartTypeEnum } from '@/types';
import { triggerEvent } from '@/utils/event';

const WarehouseArrivalForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouseArrival, setWarehouseArrival] =
    // @ts-ignore
    useState<WarehouseArrivalEntity>(initialValues);
  const [searchedProduct, setSearchedProduct] = useState<ProductEntity>();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showProductFormModal, setShowProductFormModal] = useState(false);
  const [showProductStockFormModal, setShowProductStockFormModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [individualAgents, setIndividualAgents] = useState<IndividualAgent[]>([]);
  const [isAdditionalFetching, setIsAdditionalFetching] = useState(false);
  const [legalAgents, setLegalAgents] = useState<CounterpartAccount[]>([]);
  const [products, setProducts] = useState<GroupProductEntity[]>([]);
  const [searchProducts, setSearchProducts] = useState<ProductEntity[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseEntity[]>([]);

  const isEdit = Boolean(id);

  useEffect(() => {
    const promises = [
      getIndividualAgents({
        pageNumber: 1,
        pageSize: 100,
      }),
      fetchSuppliers({
        pageNumber: 1,
        pageSize: 100,
      }),
      getWarehouses(),
    ];

    if (isEdit) {
      promises.push(getWarehouseArrivalById(id as string));
    }

    Promise.all(promises)
      .then(([individual, legal, wh, arrival]) => {
        setIndividualAgents(
          // @ts-ignore
          getAgentsByRole(individual?.items, CounterpartRoleEnum.Employee),
        );
        setLegalAgents(
          // @ts-ignore
          legal?.items.map((item) => ({
            ...item,
            counterpartProfile:
              item.counterpartType === CounterpartTypeEnum.Legal
                ? {
                    ...item?.counterpartProfile,
                    firstName: item?.counterpartProfile?.title,
                  }
                : item?.counterpartProfile,
          })),
        );
        setWarehouses(wh?.items);

        if (isEdit) {
          arrival?.products?.length &&
            setProducts(
              // @ts-ignore
              arrival?.products?.map((product) => ({
                ...product?.groupedProduct,
                count: product?.count,
                // @ts-ignore
                items: product?.items?.map((item) => ({
                  ...item?.product,
                  barcode: item?.barcode || 0,
                  buyingPrice: item?.buyingPrice || 0,
                })),
              })),
            );
          setWarehouseArrival({
            ...arrival,
            responsible: arrival?.responsible?.counterpartProfile?._id,
            supplier: arrival?.supplier?._id,
            warehouse: arrival?.warehouse?.section?._id,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isEdit]);

  const handleUpdateProductAmount = (index: number, value: number) => {
    setProducts((prev) =>
      prev.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            amount: value,
          };
        }
        return item;
      }),
    );
  };

  const goBack = () => {
    navigate(PATHS.ARRIVAL);
  };

  const handleSearchProducts = async (query: string) => {
    if (!isString(query)) return;

    try {
      const data = await fetchSearchProducts(query);

      setSearchProducts(
        data?.map((item: ProductEntity) => ({
          value: item,
          label: item.title,
        })),
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  const handleUpdateProducts = async (
    product: ProductEntity | ProductEntity[] | GroupProductEntity | GroupProductEntity[],
  ) => {
    if (!isArray(product)) {
      product = [product];
    }
    // @ts-ignore
    setProducts((prev) => prev?.concat(product));
  };

  const handleUpdateProductQuantity = async () => {
    if (isEmpty(warehouseArrival?.warehouse)) {
      return toast.error('Необходимо добавить склад');
    }

    setIsAdditionalFetching(true);

    try {
      await updateWarehouseArrival(id as string, {
        products: products.map((product) => ({
          amount: product.amount,
          product: product._id,
          barcode: product.barcode,
          buyingPrice: product.buyingPrice,
        })),
        warehouse: (warehouseArrival?.warehouse as WarehouseEntity)?._id,
      });

      const arrival = await getWarehouseArrivalById(id as string);

      setProducts(
        // @ts-ignore
        arrival?.products?.map((product) => ({
          ...product?.groupedProduct,
          count: product?.count,
          // @ts-ignore
          items: product?.items?.map((item) => ({
            ...item?.product,
            barcode: item?.barcode || 0,
            buyingPrice: item?.buyingPrice || 0,
          })),
        })),
      );
      setWarehouseArrival({
        ...arrival,
        responsible: arrival?.responsible?.counterpartProfile?._id,
        supplier: arrival?.supplier?._id,
        warehouse: arrival?.warehouse?.section?._id,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message || err?.response?.data);
      }
    } finally {
      setIsAdditionalFetching(false);
    }
  };

  const handleOnSubmit = async (values: WarehouseArrivalEntity) => {
    setIsFetching(true);

    const { accountingDate, documentType, supplier, responsible, warehouse } = values;

    try {
      const data = {
        accountingDate,
        documentType,
        // @ts-ignore
        products: products?.items?.map((product) => ({
          product: product._id,
          amount: product.amount,
          buyingPrice: product.buyingPrice,
        })),
        responsible,
        supplier,
        warehouse,
      };

      if (isEdit) {
        await updateWarehouseArrival(id as string, data);
      } else {
        await createWarehouseArrival(data);
      }

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success('Поступление добавлено');
      navigate(PATHS.ARRIVAL);
    } catch (err) {
      console.log(err);
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleShowProductModal = () => {
    if (isEmpty(warehouseArrival?.warehouse)) {
      return toast.error('Необходимо добавить склад');
    }

    setShowProductModal(true);
  };

  const handleAddProduct = async (product: ProductEntity) => {
    setSearchedProduct(product);
    handleUpdateProducts(product);
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <div className={styles.container}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        // @ts-ignore
        initialValues={warehouseArrival}
        onSubmit={handleOnSubmit}
        validationSchema={warehouseArrivalFormSchema}
      >
        {({ values, setFieldValue, errors }) => (
          <Form className={styles.form}>
            <Scrollbar>
              <Container center>
                <Flex flexDirection="column" rowGap="xl">
                  <Grid gap="sm">
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.DocumentType]}
                        name={FormFields.DocumentType}
                        label="Тип документа"
                        defaultValue={{
                          label:
                            warehouseArrivalDocuments[
                              values[FormFields.DocumentType] as WarehouseArrivalDocumentTypeEnum
                            ],
                          value: values[FormFields.DocumentType],
                        }}
                        options={Object.entries(warehouseArrivalDocuments).map(([value, label]) => ({
                          label,
                          value,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.DocumentType, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.Supplier]}
                        name={FormFields.Supplier}
                        defaultValue={{
                          label: getAgentFullName(
                            // @ts-ignore
                            legalAgents.find((item) => item._id === values[FormFields.Supplier])?.counterpartProfile,
                          ),
                          value: values[FormFields.Supplier],
                        }}
                        label="Поставщик"
                        options={legalAgents.map((legalAgent) => ({
                          label: getAgentFullName(legalAgent?.counterpartProfile),
                          value: legalAgent._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Supplier, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        label="Склад"
                        error={errors[FormFields.Warehouse]}
                        name={FormFields.Warehouse}
                        defaultValue={{
                          label: warehouses.find((item) => item.section?._id === values[FormFields.Warehouse])?.title,
                          value: values[FormFields.Warehouse],
                        }}
                        // @ts-ignore
                        options={warehouses?.map((warehouse) => ({
                          label: warehouse.title,
                          value: warehouse._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Warehouse, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={6}>
                      <Select
                        error={errors[FormFields.Responsible]}
                        name={FormFields.Responsible}
                        label="Ответственный менеджер"
                        defaultValue={{
                          label: getAgentFullName(
                            // @ts-ignore
                            individualAgents.find((item) => item._id === values[FormFields.Responsible]),
                          ),
                          value: values[FormFields.Responsible],
                        }}
                        options={individualAgents.map((individualAgent) => ({
                          label: getAgentFullName(individualAgent),
                          value: individualAgent?.account?._id,
                        }))}
                        onChange={(e) => {
                          setFieldValue(FormFields.Responsible, e!.value);
                        }}
                      />
                    </Grid.Item>
                    <Grid.Item col={12}>
                      <DatePicker
                        // @ts-ignore
                        date={values[FormFields.AccountingDate]}
                        error={errors[FormFields.AccountingDate]}
                        name={FormFields.AccountingDate}
                        maxDate={new Date()}
                        label="Дата и время учета"
                        placeholder="Выберите дату и время учета"
                        onChange={(value: Date) => {
                          setFieldValue(FormFields.AccountingDate, new Date(value));
                        }}
                      />
                    </Grid.Item>
                  </Grid>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                      <span>Товары</span>
                      <div className={styles.formSearch}>
                        <AutoComplete
                          data={searchProducts}
                          onChange={handleSearchProducts}
                          onSelect={handleAddProduct}
                          placeholder="Поиск товаров для добавления в таблицу"
                          className={styles.autocomplete}
                          value={searchedProduct?.title}
                        />
                      </div>
                      <Button endIcon={IconAdd} onClick={handleShowProductModal} variant="secondary">
                        Добавление товара
                      </Button>
                    </Flex>
                    <Flex>
                      <Button onClick={() => setShowProductStockFormModal(true)} variant="empty-primary">
                        Из остатков
                      </Button>
                      <Button onClick={() => setShowProductFormModal(true)} variant="empty-primary">
                        Из номенклатуры
                      </Button>
                    </Flex>
                  </Flex>
                  <If condition={!isEmpty(products)}>
                    <Table
                      className={styles.table}
                      header={
                        <Table.Row>
                          <Table.Cell>Название</Table.Cell>
                          <Table.Cell>Бренд</Table.Cell>
                          <Table.Cell>Артикул</Table.Cell>
                          <Table.Cell>Количество</Table.Cell>
                          <Table.Cell>Текущая цена поставки</Table.Cell>
                          <Table.Cell>Итоговая сумма</Table.Cell>
                        </Table.Row>
                      }
                    >
                      {products?.map((product, index) => (
                        <ProductAccordion
                          key={index}
                          index={index}
                          isFetching={isAdditionalFetching}
                          onSave={handleUpdateProductQuantity}
                          handleUpdateProductAmount={handleUpdateProductAmount}
                          product={product}
                        />
                        // <Product
                        //   key={index}
                        //   isFetching={isAdditionalFetching}
                        //   index={index}
                        //   handleUpdateProductBarcode={
                        //     handleUpdateProductBarcode
                        //   }
                        //   handleUpdateProductAmount={handleUpdateProductAmount}
                        //   handleUpdateProductBuyingPrice={
                        //     handleUpdateProductBuyingPrice
                        //   }
                        //   product={product}
                        //   onSave={handleUpdateProductQuantity}
                        // />
                      ))}
                    </Table>
                  </If>
                </Flex>
              </Container>
            </Scrollbar>
            <Flex className={styles.formActions} alignItems="center" columnGap="sm">
              <Button disabled={isFetching} isLoading={isFetching} type="submit">
                {isEdit ? 'Сохранить поставку' : 'Принять поставку'}
              </Button>
              <Button disabled={isFetching} onClick={goBack} variant="secondary">
                Закрыть
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>

      <ProductForm
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
        onSuccess={handleUpdateProducts}
        warehouseArrival={warehouseArrival}
      />

      <If condition={showProductStockFormModal}>
        <WarehouseProductStockModal
          isOpen={true}
          onSuccess={handleUpdateProducts}
          onClose={() => setShowProductStockFormModal(false)}
        />
      </If>

      <If condition={showProductFormModal}>
        <WarehouseProductModal
          isOpen={true}
          onSuccess={handleUpdateProducts}
          onClose={() => setShowProductFormModal(false)}
        />
      </If>
    </div>
  );
};

const ProductAccordion = ({
  index,
  isFetching,
  handleUpdateProductAmount,
  onSave,
  product,
}: {
  index: number;
  isFetching: boolean;
  handleUpdateProductAmount: (index: number, value: number) => void;
  onSave: () => void;
  product: GroupProductEntity;
}) => {
  const [quantity, setQuantity] = useState(product?.count || 0);
  const [opened, setOpened] = useState(false);

  const toggleOpened = () => {
    setOpened((prev) => !prev);
  };

  return (
    <>
      <Table.Row className={styles.tableRow}>
        <Table.Cell>{product?.title}</Table.Cell>
        <Table.Cell>{product?.brand}</Table.Cell>
        <Table.Cell>{product?.article}</Table.Cell>
        <Table.Cell>
          <Flex alignItems="center">
            <IconButton
              disabled={quantity < 2}
              icon={IconMinus}
              onClick={() => {
                setQuantity(quantity - 1);
                handleUpdateProductAmount(index, quantity - 1);
              }}
              size="xs"
              className="rounded-lg"
              variant="secondary"
            />
            {quantity}
            <IconButton
              icon={IconPlus}
              onClick={() => {
                setQuantity(quantity + 1);
                handleUpdateProductAmount(index, quantity + 1);
              }}
              size="xs"
              className="rounded-lg"
              variant="secondary"
            />
          </Flex>
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell className="!pr-3">
          <Flex alignItems="center" justifyContent="space-between" className="w-full">
            {(product?.amount || 1) * (product?.buyingPrice || 0)} ₽
            <Flex alignItems="center" columnGap="xxs">
              <IconButton icon={opened ? IconArrowUp : IconArrowDown} onClick={toggleOpened} />
              <IconButton
                disabled={isFetching || product?.count === quantity}
                icon={IconCheck}
                onClick={onSave}
                variant="primary"
                size="sm"
              />
            </Flex>
          </Flex>
        </Table.Cell>
      </Table.Row>
      {opened && (
        <>
          {product?.items.map((item) => (
            <Table.Row className={styles.tableRow}>
              <Table.Cell colSpan={6}>
                <Flex className="py-3" columnGap="xxs">
                  <IconArrowSubDown fill="#7C7A85" size={20} />
                  <Flex flexDirection="column" rowGap="xxs">
                    <span className="text-[10px]">Штрихкод</span>
                    <span className="text-[12px]">{item.barcode}</span>
                  </Flex>
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      )}
    </>
  );
};

export default WarehouseArrivalForm;
