import * as Yup from 'yup';

import { FormFields } from './constants';

import type { CarServiceSubdivisionEntity } from '@/features/Subdivision/types';
import * as messages from '@/utils/validationMessages';

// @ts-ignore
export const serviceFormSchema: Yup.ObjectSchema<CarServiceSubdivisionEntity> = Yup.object().shape({
  [FormFields.Address]: Yup.string().required(messages.required),
  [FormFields.Inn]: Yup.string().required(messages.required),
  [FormFields.Title]: Yup.string().required(messages.required),
});
