import { AudioPlayer } from '@/components/AudioPlayer';
import type { TelephonyCallModalProps } from './types';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import IconClose from '@/components/Icons/IconClose';
import { useEffect, useState } from 'react';
import { fetchDownloadCall } from '@/features/Telephony/services';
import { useMutation, useQuery } from 'react-query';
import IconLoading from '@/components/Icons/IconLoading';
import { useModalContext } from '@/components/UI/Modal';
import { toast } from 'react-toastify';

export const TelephonyCallModal = ({ callId }: TelephonyCallModalProps) => {
  const { onClose } = useModalContext();
  const { data, isLoading, error } = useQuery({
    queryKey: ['downloadCall', callId],
    queryFn: async () => {
      return URL.createObjectURL(await fetchDownloadCall(callId));
    },
  });

  useEffect(() => {
    if (error) {
      toast.error('Не удалось загрузить запись');
      onClose?.();
    }
  }, [error]);

  if (error) {
    return null;
  }

  return (
    <Flex className="w-[634px] min-h-[116px] rounded-[8px] p-4 bg-white" rowGap="sm" flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className="text-sm text-dark-primary">Запись звонка</h2>
        <IconButton icon={IconClose} onClick={onClose!} size="xs" />
      </Flex>
      {isLoading ? (
        <div className="grid place-items-center relative top-2">
          <IconLoading width={28} height={28} />
        </div>
      ) : (
        <AudioPlayer source={data as string} />
      )}
    </Flex>
  );
};
