import Pagination from '@/components/UI/Pagination';
import Table from '@/components/UI/Table';
import { Scrollbar } from 'react-scrollbars-custom';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { If } from '@/components/ConditionalRendering/If';
import { useTable } from '@/hooks';
import { getIndividualAgents } from '@/pages/Agents/components/AgentsListTable/service';
import styles from './styles.module.scss';
import { getAgentFullName } from '@/pages/AgentDetail/utils';

export const EmployeeListTable = () => {
  const { pageNumber, pageSize, totalPages, totalItems, handlePageChange, setTotalItems, setTotalPages } = useTable();

  const { data, isLoading } = useQuery({
    queryKey: ['agents', pageNumber, pageSize],
    queryFn: async () => {
      const data = await getIndividualAgents({ pageNumber, pageSize });
      setTotalPages(data.pagination.totalPages || 1);
      setTotalItems(data.pagination.totalItems || 0);
      return data;
    },
  });

  return (
    <>
      <Scrollbar>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <Table.Cell>Полное имя</Table.Cell>
            </Table.Row>
          }
          isEmpty={isEmpty(data?.items)}
          isFetching={isLoading}
        >
          {data?.items.map((agent) => (
            <Table.Row key={agent._id}>
              <Table.Cell>{getAgentFullName(agent)}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={!isEmpty(data?.items)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};
