export const IconVolumeOff = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 17.7499H5C2.58 17.7499 1.25 16.4199 1.25 13.9999V9.99987C1.25 7.57987 2.58 6.24987 5 6.24987H6.43C6.66 6.24987 6.89 6.17987 7.09 6.05987L10.01 4.22987C11.47 3.31987 12.89 3.14987 14.01 3.76987C15.13 4.38987 15.74 5.67987 15.74 7.40987V8.36987C15.74 8.77987 15.4 9.11987 14.99 9.11987C14.58 9.11987 14.24 8.77987 14.24 8.36987V7.40987C14.24 6.26987 13.89 5.41987 13.28 5.08987C12.67 4.74987 11.77 4.89987 10.8 5.50987L7.88 7.32987C7.45 7.60987 6.94 7.74987 6.43 7.74987H5C3.42 7.74987 2.75 8.41987 2.75 9.99987V13.9999C2.75 15.5799 3.42 16.2499 5 16.2499H7C7.41 16.2499 7.75 16.5899 7.75 16.9999C7.75 17.4099 7.41 17.7499 7 17.7499Z"
      fill="#292D32"
    />
    <path
      d="M12.5501 20.5902C11.7601 20.5902 10.8901 20.3102 10.0201 19.7602C9.67009 19.5402 9.56009 19.0802 9.78009 18.7302C10.0001 18.3802 10.4601 18.2702 10.8101 18.4902C11.7801 19.0902 12.6801 19.2502 13.2901 18.9102C13.9001 18.5702 14.2501 17.7202 14.2501 16.5902V12.9502C14.2501 12.5402 14.5901 12.2002 15.0001 12.2002C15.4101 12.2002 15.7501 12.5402 15.7501 12.9502V16.5902C15.7501 18.3102 15.1301 19.6102 14.0201 20.2302C13.5701 20.4702 13.0701 20.5902 12.5501 20.5902Z"
      fill="#292D32"
    />
    <path
      d="M18 16.7498C17.84 16.7498 17.69 16.6998 17.55 16.5998C17.22 16.3498 17.15 15.8798 17.4 15.5498C18.66 13.8698 18.93 11.6398 18.12 9.70983C17.96 9.32983 18.14 8.88983 18.52 8.72983C18.9 8.56983 19.34 8.74983 19.5 9.12983C20.52 11.5498 20.17 14.3598 18.6 16.4598C18.45 16.6498 18.23 16.7498 18 16.7498Z"
      fill="#292D32"
    />
    <path
      d="M19.8301 19.2502C19.6701 19.2502 19.5201 19.2002 19.3801 19.1002C19.0501 18.8502 18.9801 18.3802 19.2301 18.0502C21.3701 15.2002 21.8401 11.3802 20.4601 8.0902C20.3001 7.7102 20.4801 7.2702 20.8601 7.1102C21.2501 6.9502 21.6801 7.1302 21.8401 7.5102C23.4301 11.2902 22.8901 15.6702 20.4301 18.9502C20.2901 19.1502 20.0601 19.2502 19.8301 19.2502Z"
      fill="#292D32"
    />
    <path
      d="M1.99994 22.7499C1.80994 22.7499 1.61994 22.6799 1.46994 22.5299C1.17994 22.2399 1.17994 21.7599 1.46994 21.4699L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L2.52994 22.5299C2.37994 22.6799 2.18994 22.7499 1.99994 22.7499Z"
      fill="#292D32"
    />
  </svg>
);
