import api from '@/api';
import { CallEntity, CallsEntity } from '@/features/Telephony/types';
import { ResponseFromServer, ResponseFromService } from '@/types';
import { OrderEntity } from '@/features/Order/types';

export const fetchGetCalls = async (): Promise<ResponseFromServer<CallEntity>> => {
  const response = await api.get('/call');
  return response.data;
};

export const fetchDownloadCall = async (callId: string) => {
  const response = await api.get(`/call/download-record?recordId=${callId}`, {
    responseType: 'blob',
  });
  return response.data;
};
