import Routes from 'src/components/Routes';
import { ToastContainer } from 'react-toastify';
import { Providers } from '@/components/Providers';
import { useCheckAuth } from '@/hooks';

const App = () => {
  useCheckAuth();

  return (
    <Providers>
      <Routes />
      <ToastContainer />
    </Providers>
  );
};

export default App;
