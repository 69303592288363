import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import ReactModal from 'react-modal';

import cn from 'classnames';

import styles from './styles.module.scss';
import type { ModalProps } from './types';

ReactModal.setAppElement('#modal');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'visible',
  },
};

type ModalContextType = {
  onClose?: () => void;
};

const ModalContext = createContext({} as ModalContextType);

const Modal = ({ children, isOpen, className, onClose }: PropsWithChildren<ModalProps>) => {
  const value = useMemo(() => ({ onClose }), [onClose]);

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <ModalContext.Provider value={value}>
        <div className={cn(styles.modal, className)}>{children}</div>
      </ModalContext.Provider>
    </ReactModal>
  );
};

export const useModalContext = () => useContext(ModalContext);

export default Modal;
