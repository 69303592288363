import { useCallback, useEffect, useRef, useState } from 'react';
import { If } from '@/components/ConditionalRendering/If';
import Flex from '@/components/UI/Flex';
import IconButton from '@/components/UI/IconButton';
import { IconPause } from '@/components/Icons/IconPause';
import IconPlay from '@/components/Icons/IconPlay';
import { formatTime } from './constants';
import type { AudioPlayerProps } from './types';
import styles from './styles.module.scss';
import { IconVolume } from '@/components/Icons/IconVolume';
import { IconVolumeOff } from '@/components/Icons/IconVolumeOff';

export const AudioPlayer = ({ source }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const playAnimationRef = useRef<number | null>(null);

  const [duration, setDuration] = useState(0);
  const [muteVolume, setMuteVolume] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeProgress, setTimeProgress] = useState<number>(0);

  const togglePlaying = () => {
    setIsPlaying((prev) => !prev);
  };

  const toggleVolume = () => {
    setMuteVolume((prev) => !prev);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = muteVolume;
    }
  }, [audioRef, muteVolume]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
      startAnimation();
    } else {
      audioRef.current?.pause();
      if (playAnimationRef.current !== null) {
        cancelAnimationFrame(playAnimationRef.current);
        playAnimationRef.current = null;
      }
      updateProgress();
    }

    return () => {
      if (playAnimationRef.current !== null) {
        cancelAnimationFrame(playAnimationRef.current);
      }
    };
  }, [isPlaying, audioRef]);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current?.duration;
    if (seconds !== undefined) {
      setDuration(seconds);
      if (progressBarRef.current) {
        progressBarRef.current.max = seconds.toString();
      }
    }
  };

  const updateProgress = useCallback(() => {
    if (audioRef.current && progressBarRef.current && duration) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);

      progressBarRef.current.value = currentTime.toString();
      progressBarRef.current.style.setProperty('--range-progress', `${(currentTime / duration) * 100}%`);
    }
  }, [duration, setTimeProgress, audioRef, progressBarRef]);

  const startAnimation = useCallback(() => {
    if (audioRef.current && progressBarRef.current && duration) {
      const animate = () => {
        updateProgress();
        playAnimationRef.current = requestAnimationFrame(animate);
      };
      playAnimationRef.current = requestAnimationFrame(animate);
    }
  }, [updateProgress, duration, audioRef, progressBarRef]);

  const handleProgressChange = () => {
    if (audioRef.current && progressBarRef.current) {
      const newTime = Number(progressBarRef.current.value);
      audioRef.current.currentTime = newTime;
      setTimeProgress(newTime);
      progressBarRef.current.style.setProperty('--range-progress', `${(newTime / duration) * 100}%`);
    }
  };

  return (
    <div>
      <If condition={Boolean(source)}>
        <audio
          ref={audioRef}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onLoadedMetadata={onLoadedMetadata}
          src={source}
        />
      </If>
      <Flex columnGap="xs">
        <IconButton
          className="!rounded-full"
          icon={isPlaying ? IconPause : IconPlay}
          onClick={togglePlaying}
          variant="dark"
          size="xl"
        />
        <Flex className="!gap-x-[2px] flex-1" rowGap="xxs" flexDirection="column">
          <Flex columnGap="xs" alignItems="center">
            <div className={styles.visualizer}>
              <input
                ref={progressBarRef}
                className={styles.range}
                onChange={handleProgressChange}
                type="range"
                defaultValue="0"
              />
            </div>
            <div>
              <IconButton icon={muteVolume ? IconVolumeOff : IconVolume} onClick={toggleVolume} size="xxs" />
            </div>
          </Flex>

          <Flex columnGap="zero" className="text-xs">
            <span className="inline-block min-w-[35px]">{formatTime(timeProgress)}</span>
            <span className="inline-block ml-1 mr-2">/</span>
            <span>{formatTime(duration)}</span>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
