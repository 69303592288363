import Header from '@/components/Header';
import { EmployeeListTable } from '@/features/Employee';
import styles from './styles.module.scss';
import IconAdd from '@/components/Icons/IconAdd';
import Button from '@/components/UI/Button';

export const EmployeeView = () => {
  return (
    <div className={styles.container}>
      <Header
        title="Работники"
        actions={
          <Button endIcon={IconAdd} variant="default">
            Работник
          </Button>
        }
        showSearch={false}
      />
      <EmployeeListTable />
    </div>
  );
};
