import type { Menu } from './types';

import IconBriefcase from '@/components/Icons/IconBriefcase';
import IconCalendar from '@/components/Icons/IconCalendar';
import IconCar from '@/components/Icons/IconCar';
import IconSettings from '@/components/Icons/IconSettings';
import IconStock from '@/components/Icons/IconStock';
import IconUserSquare from '@/components/Icons/IconUserSquare';
import IconWallet from '@/components/Icons/IconWallet';
import { PATHS } from '@/constants';
import IconCall from '@/components/Icons/IconCall';

export const menu: Menu[] = [
  {
    icon: IconCar,
    title: 'Сделки',
    path: PATHS.ORDER,
  },
  {
    icon: IconBriefcase,
    title: 'Каталог работ',
    path: PATHS.JOBS,
  },
  {
    icon: IconStock,
    title: 'Склад',
    path: PATHS.STOCK,
    submenu: [
      {
        title: 'Поступления',
        path: PATHS.ARRIVAL,
      },
      // {
      //   title: "Остатки",
      //   path: PATHS.REMAINS,
      // },
      // {
      //   title: "Товарный каталог",
      //   path: PATHS.GOODS,
      // },
      {
        title: 'Перемещения',
        path: PATHS.TRANSFER,
      },
      // {
      //   title: "Списания",
      //   path: PATHS.DEBT,
      // },
      // {
      //   title: "Коррекция",
      //   path: PATHS.CORRECTION,
      // },
    ],
  },
  {
    icon: IconCalendar,
    title: 'Календарь',
    path: PATHS.CALENDAR,
  },
  {
    icon: IconWallet,
    title: 'Финансы',
    path: PATHS.FINANCE,
    submenu: [
      // {
      //   title: "Баланс",
      //   path: PATHS.BALANCE,
      // },
      // {
      //   title: "Затраты",
      //   path: PATHS.EXPENSES,
      // },
      {
        title: 'ДДС',
        path: PATHS.PAYMENTS,
      },
      // {
      //   title: "Сверка по агенту",
      //   path: PATHS.FINANCE_CHECK,
      // },
      // {
      //   title: "Ведомость по контрагентам",
      //   path: PATHS.FINANCE_STATEMENT,
      // },
      // {
      //   title: "Прибыль по отгрузке",
      //   path: PATHS.SHIPMENT_PROFIT,
      // },
    ],
  },
  {
    icon: IconUserSquare,
    title: 'Контрагенты',
    path: PATHS.AGENTS,
  },
  {
    icon: IconCall,
    title: 'Звонки',
    path: PATHS.TELEPHONY,
  },
  // {
  //   icon: IconCall,
  //   title: "Звонки",
  //   path: PATHS.CALLS,
  // },
  // {
  //   icon: IconCoins,
  //   title: "Зарплата",
  //   path: PATHS.SALARY,
  //   submenu: [
  //     {
  //       title: "Рабочий график",
  //       path: PATHS.WORK_SCHEDULE,
  //     },
  //     {
  //       title: "Выработка",
  //       path: PATHS.SALARY_PRODUCTION,
  //     },
  //     {
  //       title: "Формулы расчета зарплат",
  //       path: PATHS.SALARY_CALCULATION,
  //     },
  //   ],
  // },
  {
    icon: IconSettings,
    title: 'Настройки',
    path: PATHS.SETTINGS,
    submenu: [
      {
        title: 'Основные',
        path: PATHS.SETTINGS,
      },
      {
        title: 'Балансы и касса',
        path: PATHS.PAYMENT_TERMINAL,
      },
      {
        title: 'Склад',
        path: PATHS.WAREHOUSE,
      },
      {
        title: 'Сделки',
        path: PATHS.SETTINGS_ORDERS,
      },
      {
        title: 'Конфигуратор',
        path: PATHS.SETTINGS_DIRECTORY,
      },
      {
        title: 'Работники',
        path: PATHS.EMPLOYEE,
      },
      // {
      //   title: "Выработка",
      //   path: PATHS.SALARY_PRODUCTION,
      // },
      // {
      //   title: "Формулы расчета зарплат",
      //   path: PATHS.SALARY_CALCULATION,
      // },
    ],
  },
];
