import { DayOfWeekEnum } from '@/types';

export const sheduler = [
  {
    label: 'Понедельник',
    value: DayOfWeekEnum.Monday,
  },
  {
    label: 'Вторник',
    value: DayOfWeekEnum.Thursday,
  },
  {
    label: 'Среда',
    value: DayOfWeekEnum.Wednesday,
  },
  {
    label: 'Четверг',
    value: DayOfWeekEnum.Saturday,
  },
  {
    label: 'Пятница',
    value: DayOfWeekEnum.Friday,
  },
  {
    label: 'Суббота',
    value: DayOfWeekEnum.Saturday,
  },
  {
    label: 'Воскресенье',
    value: DayOfWeekEnum.Sunday,
  },
];

export enum TimeZoneEnum {
  Kaliningrad = 2, // UTC+2 (MSK-1)
  Moscow = 3, // UTC+3 (MSK)
  Samara = 4, // UTC+4 (MSK+1)
  Yekaterinburg = 5, // UTC+5 (MSK+2)
  Omsk = 6, // UTC+6 (MSK+3)
  Krasnoyarsk = 7, // UTC+7 (MSK+4)
  Irkutsk = 8, // UTC+8 (MSK+5)
  Yakutsk = 9, // UTC+9 (MSK+6)
  Vladivostok = 10, // UTC+10 (MSK+7)
  Magadan = 11, // UTC+11 (MSK+8)
  Kamchatka = 12, // UTC+12 (MSK+9)
}

export const timezones = [
  {
    label: 'Калининград',
    value: TimeZoneEnum.Kaliningrad,
  },
  {
    label: 'Москва',
    value: TimeZoneEnum.Moscow,
  },
  {
    label: 'Самара',
    value: TimeZoneEnum.Samara,
  },
  {
    label: 'Екатеринбург',
    value: TimeZoneEnum.Yekaterinburg,
  },
  {
    label: 'Омск',
    value: TimeZoneEnum.Omsk,
  },
  {
    label: 'Красноярск',
    value: TimeZoneEnum.Krasnoyarsk,
  },
  {
    label: 'Иркутск',
    value: TimeZoneEnum.Irkutsk,
  },
  {
    label: 'Якутск',
    value: TimeZoneEnum.Yakutsk,
  },
  {
    label: 'Владивосток',
    value: TimeZoneEnum.Vladivostok,
  },
  {
    label: 'Магадан',
    value: TimeZoneEnum.Magadan,
  },
  {
    label: 'Камчатка',
    value: TimeZoneEnum.Kamchatka,
  },
];

export enum FormFields {
  Address = 'address',
  Inn = 'inn',
  Owner = 'owner',
  Schedule = 'schedule',
  Title = 'title',
  Website = 'website',
}

export const initialValues = {
  [FormFields.Address]: '',
  [FormFields.Owner]: '',
  [FormFields.Inn]: '',
  [FormFields.Schedule]: sheduler.map(() => ({
    day: null,
    workStart: null,
    workEnd: null,
  })),
  [FormFields.Title]: '',
};

export type ShedulerType = (typeof sheduler)[number];
