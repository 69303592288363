import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';
import { toast } from 'react-toastify';

import { isAxiosError } from 'axios';
import { Form, Formik } from 'formik';

import SettingsServicesSchedule from '../SettingsServicesSchedule';

import { initialValues, timezones } from './constants';
import { FormFields } from './constants';
import { serviceFormSchema } from './schema';
import styles from './styles.module.scss';

import { If } from '@/components/ConditionalRendering/If';
import Container from '@/components/Container';
import IconAdd from '@/components/Icons/IconAdd';
import IconArrowUp from '@/components/Icons/IconArrowUp';
import IconCheck from '@/components/Icons/IconCheck';
import IconTrash from '@/components/Icons/IconTrash';
import Button from '@/components/UI/Button';
import Flex from '@/components/UI/Flex';
import Grid from '@/components/UI/Grid';
import IconButton from '@/components/UI/IconButton';
import Input from '@/components/UI/Input';
import Section from '@/components/UI/Section';
import Select from '@/components/UI/Select';
import Switch from '@/components/UI/Switch';
import { EVENTS, PATHS } from '@/constants';
import { createSubdivision } from '@/features/Subdivision/services';
import type { CarServiceSubdivisionEntity } from '@/features/Subdivision/types';
import { useAppProvider } from '@/providers/AppProvider/hooks';
import { triggerEvent } from '@/utils/event';

const SettingsServicesForm = () => {
  const navigate = useNavigate();
  const { currentSubdivision } = useAppProvider();
  const [service] = useState<CarServiceSubdivisionEntity>(
    // @ts-ignore
    () => initialValues,
  );
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (values: CarServiceSubdivisionEntity) => {
    setIsFetching(true);

    try {
      const data = {
        ...values,
        owner: currentSubdivision?.owner?._id,
        schedule: values.schedule.filter((schedule) => Boolean(schedule.day)),
      };

      await createSubdivision(data);
      triggerEvent(EVENTS.REFRESH_DATA);
      navigate(PATHS.SETTINGS);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    } finally {
      setIsFetching(true);
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={service}
      validationSchema={serviceFormSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleChange, values }) => (
        <Form className={styles.form}>
          <Scrollbar>
            <Container center>
              <Flex flexDirection="column" rowGap="lg">
                <Grid>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.Title]}
                      name={FormFields.Title}
                      onChange={handleChange}
                      value={values[FormFields.Title]}
                      label="Название"
                      placeholder="Введите название"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      error={errors[FormFields.Address]}
                      name={FormFields.Address}
                      onChange={handleChange}
                      value={values[FormFields.Address]}
                      label="Адрес"
                      placeholder="Введите адрес"
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      name={FormFields.Website}
                      onChange={handleChange}
                      value={values[FormFields.Website]}
                      label="Сайт"
                      placeholder="Введите сайт"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Input label="Контактный номер телефона" placeholder="Введите контактный номер телефона" />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Input
                      error={errors[FormFields.Inn]}
                      name={FormFields.Inn}
                      onChange={handleChange}
                      value={values[FormFields.Inn]}
                      label="ИНН"
                      placeholder="Введите ИНН"
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      options={timezones.map(({ label, value }) => ({
                        label,
                        value,
                      }))}
                      label="Часовой пояс подразделения (UTC)"
                      placeholder="Введите название"
                    />
                  </Grid.Item>
                </Grid>

                <SettingsServicesSchedule />

                <If condition={false}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <h2 className={styles.title}>Банковские счета</h2>
                    <Button endIcon={IconAdd} variant="empty-primary">
                      Банковский счет
                    </Button>
                  </Flex>

                  <Section className={styles.section}>
                    <Flex className={styles.sectionTop} alignItems="center" justifyContent="space-between">
                      <h2 className={styles.title}>Новый банковский счет</h2>
                      <IconButton onClick={() => null} variant="stroke" icon={IconArrowUp} />
                    </Flex>
                    <If condition={true}>
                      <Grid className={styles.content}>
                        <Grid.Item col={4}>
                          <Input label="Расчетный счет" placeholder="Введите расчетный счет" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="Название банка" placeholder="Введите название банка" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="БИК" placeholder="Введите БИК" />
                        </Grid.Item>

                        <Grid.Item col={4}>
                          <Input label="Корреспондентский счет" placeholder="Введите корреспондентский счет" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="КПП" placeholder="Введите КПП" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="Адрес банка" placeholder="Введите адрес банка" />
                        </Grid.Item>

                        <Grid.Item col={4}>
                          <Input label="ОГРН" placeholder="Введите ОГРН" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input
                            label="Юридический адрес организации"
                            placeholder="Введите юридический адрес организации"
                          />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="Бухгалтер" placeholder="Введите бухгалтер" />
                        </Grid.Item>

                        <Grid.Item col={4}>
                          <Input label="Номер контакта" placeholder="Введите номер контакта" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="Генеральный директор" placeholder="Введите генерального директора" />
                        </Grid.Item>
                        <Grid.Item col={4}>
                          <Input label="Директор" placeholder="Введите директора" />
                        </Grid.Item>

                        <Grid.Item col={6}>
                          <Select label="Система налооблажения" />
                        </Grid.Item>

                        <Grid.Item col={6}>
                          <Select label="Налоговая ставка" />
                        </Grid.Item>
                      </Grid>
                    </If>
                    <Flex className={styles.footer}>
                      <Button>Сохранить</Button>
                      <Button variant="stroke-danger">Удалить</Button>
                    </Flex>
                  </Section>

                  <Flex alignItems="center" justifyContent="space-between">
                    <h2 className={styles.title}>Добавить подразделение</h2>
                    <Button endIcon={IconAdd} variant="empty-primary">
                      Подразделение
                    </Button>
                  </Flex>

                  <Section>
                    <Flex columnGap="sm">
                      <Input label="Название" placeholder="Введите название" />
                      <Flex alignItems="flex-end" columnGap="xs">
                        <IconButton icon={IconCheck} onClick={() => null} size="xl" variant="primary" />
                        <IconButton icon={IconTrash} onClick={() => null} size="xl" variant="stroke" />
                      </Flex>
                    </Flex>
                    <Switch value="Активное подразделение" />
                  </Section>
                </If>
              </Flex>
            </Container>
          </Scrollbar>

          <Flex className={styles.formActions} alignItems="center" columnGap="sm">
            <Button disabled={isFetching} isLoading={isFetching} type="submit">
              Сохранить
            </Button>
            <Button disabled={isFetching} variant="secondary">
              Закрыть
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default SettingsServicesForm;
